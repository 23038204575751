// window.axios = require('axios');
// import Alpine from "alpinejs";
// import Fuse from "fuse.js";

// window.Fuse = Fuse;
// window.Alpine = Alpine;

// Alpine.start();

(function () {

    var ele = document.getElementById('webhooks');
    var eleEstPrice = document.getElementById('price');

    function onValueChange(e) {
        const pricingModel = {
            50: [3, 5000],
            100: [5, '10,000'],
            200: [8, '15,000'],
            300: [10, '20,000'],
            500: [15, '30,000'],
            1000: [25, '50,000'],
            2000: [40, '100,000'],
            3000: [60, '150,000'],
            5000: [90, '250,000'],
            15000: [199, '750,000'],
            'unlimited': [499, 'Unlimited']
        };
        var value = this.value;
        eleEstPrice.innerHTML = '$' + pricingModel[value][0];
        document.getElementById('cflowOps').innerHTML = pricingModel[value][1] + ' cFlows ops/month';
    }

    ele.addEventListener('change', onValueChange, false);

    ele.dispatchEvent(new Event('change'));

    var cookieAccepted = window.localStorage.getItem('cookieAccepted');
    if (!cookieAccepted) {
        var btn = document.getElementById('btnCookiesConsent');
        var cookieConsent = document.getElementById('cookiesConsent');
        cookieConsent.classList.remove('hidden');
        btn.addEventListener('click', function () {
            window.localStorage.setItem('cookieAccepted', true);
            cookieConsent.classList.add('hidden');
        }, false);
    }

}());


